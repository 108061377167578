// Global Nav Position Setting
document.addEventListener('DOMContentLoaded', function () {
    const mq = window.matchMedia('(max-width: 1024px)');

    function checkBreakPoint(mq) {
        if (mq.matches) {
            $('.gNav').prependTo('.spNav');
            $('.gNav').removeClass('align-center');
            $('.gNav').addClass('vertical');
        } else {
            $('.gNav').appendTo('.gNav-wrapper nav');
            $('.gNav').addClass('align-center');
            $('.gNav').removeClass('vertical');
        }
    }
    mq.addListener(checkBreakPoint);
    checkBreakPoint(mq);
    // Slick setting
    $('.slick-carousel').slick({
        autoplay:true
    });
// Slickの再生・停止
$('.slick-stop').on('click', function() {
    $('.slick-carousel')
        .slick('slickPause')
});
$('.slick-play').on('click', function() {
    $('.slick-carousel')
        .slick('slickPlay')
});
    const headerHeight = 200;
    $('a[href^="#"]' + 'a:not(.tab-link,.blockskip .show-on-focus)').click(function(){
      const href= $(this).attr("href");
      const target = $(href == "#" || href == "" ? 'html' : href);
      const position = target.offset().top-headerHeight; 
      $("html, body").animate({scrollTop:position}, 200, "swing");
    // 移動先にfocusを当てる
    target.focus();
    if (target.is(":focus")) {
      return false;
    } else {
      target.attr('tabindex', '-1');
      target.focus();
    };      
      return false;
    });
  });